<template/>

<script>
import GoogleTagManager from '@/plugins/google-tag-manager'
import Hotjar from '@/plugins/hotjar'
import GtmHelpers from '@/mixins/gtmHelpers'

export default {
  name: 'IdleLoader',
  mixins: [GtmHelpers],
  mounted () {
    this.loadPlugins()
  },
  methods: {
    loadPlugins() {
      setTimeout(() => {
        this.loadAttentiveTag()
        this.loadLiveChat()
        this.loadGoogleTagManager()
        this.loadHotjar()
      }, 5000)
    },
    loadAttentiveTag () {
      this.pushDataToDataLayer({ event: 'LoadAttentiveTag' })
    },
    loadLiveChat () {
      this.pushDataToDataLayer({ event: 'LoadLiveChat' })
    },
    loadGoogleTagManager () {
      GoogleTagManager(this)
    },
    loadHotjar () {
      Hotjar(this)
    }
  }
}
</script>
